(function () {
  'use strict';

  angular
    .module('dashboard.modules')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.modules', {
        url: '/modules',
        templateUrl: 'dashboard/modules/modules.tpl.html',
        controller: 'ModulesCtrl',
        roles: ['supermodules', 'modules', 'Teacher'],
        controllerAs: 'modules'
      });
  }
}());
